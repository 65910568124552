import lottie from "lottie-web";

function animationLottie(classContainer, jsonPath) {
  const container = document.querySelectorAll(classContainer);
  const directionMenu = 1;

  container.forEach((element) => {
    const animationMenu = lottie.loadAnimation({
      container: element,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: jsonPath,
    });

    element.parentElement.addEventListener("mouseenter", () => {
      animationMenu.setDirection(directionMenu);
      animationMenu.play();
    });

    element.parentElement.addEventListener("mouseleave", () => {
      animationMenu.setDirection(-directionMenu);
      animationMenu.play();
    });
  });
}

// Arrow Circle Black
export const arrowCircleBlackAnimation = () => {
  const classContainer = ".js-arrow-circle-black";
  const jsonPath = "/images/animation/circle-arrow-black.json";
  animationLottie(classContainer, jsonPath);
};

export default arrowCircleBlackAnimation;
