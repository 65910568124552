module.exports = function () {
  const header = document.querySelector("header");
  if (!header) return;

  let headerHight = header.clientHeight + "px";

  let scrollPoint = 0; // 現在のスクロール位置をセットする変数
  let lastPoint = 0; // 1つ前のスクロール位置をセットする変数

  window.addEventListener("resize", () => {
    headerHight = header.clientHeight + "px";
  });

  window.addEventListener("scroll", () => {
    scrollPoint = window.scrollY;

    if (scrollPoint > lastPoint) {
      header.style.transform = `translate3d(0, -${headerHight}, 0)`;
    } else {
      header.style.transform = `translate3d(0, 0, 0)`;
    }

    lastPoint = scrollPoint;
    if (lastPoint <= 0) {
      header.style.transform = `translate3d(0, 0, 0)`;
    }
  });
};
