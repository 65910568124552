// Hamburger Animation
const naviButton = document.querySelector("#js-navi-button");
const navi = document.querySelector("#js-navi");
const naviList = document.querySelector("#js-navi-list");
const breakpoint = 1024;

export default () => {
  // ハンバーガーメニューのクリック時
  naviButton.addEventListener("click", naviToggle);

  // ナビゲーションのクリック時
  naviList.firstElementChild.addEventListener("click", naviToggle);

  // ウインドウのリサイズ時のアクション
  window.addEventListener("resize", () => {
    let bodyWidth = document.documentElement.clientWidth;
    if (breakpoint < bodyWidth) {
      // リサイズ時のアニメーションを停止
      if (!naviButton.classList.contains("js-closed")) {
        naviButton.classList.add("js-closed");
      }
      naviButton.classList.remove("is-active");
      navi.classList.remove("is-active");
      document.querySelector("body").style.overflow = "";
      document.querySelector("html").style.overflow = "";
    }
  });

  // 開閉時の切り替え
  function naviToggle() {
    // ページ読み込み時のアニメーションを停止
    if (naviButton.classList.contains("js-closed")) {
      naviButton.classList.remove("js-closed");
    }
    let bodyWidth = document.documentElement.clientWidth;
    if (bodyWidth < breakpoint) {
      naviButton.classList.toggle("is-active");
      navi.classList.toggle("is-active");
      if (naviButton.classList.contains("is-active")) {
        document.querySelector("body").style.overflow = "hidden";
        document.querySelector("html").style.overflow = "hidden";
      } else {
        document.querySelector("body").style.overflow = "";
        document.querySelector("html").style.overflow = "";
      }
    }
  }
};
