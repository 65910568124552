export const webFontFontsource = () => {
  const WebFont = require("webfontloader");

  const WebFontConfig = {
    custom: {
      families: ["Noto Sans JP:n4,n5,n7", "Lato:n4,n7"],
      urls: ["/build/css/app.css"],
    },
  };

  WebFont.load(WebFontConfig);
};
