import { fadeInUp } from "./gsap";
import { backgroundColorChange } from "./gsap";
import { heroMovie } from "./ytplayer";
import { heroSwiper } from "./swiper";
import hamburgerAnimation from "./hamburger-animation";
import headerScrollUpDisplay from "./header-scroll-up-display";
import { arrowCircleBlackAnimation } from "./lottie";
import smoothScroll from "./smooth-scroll";
import { webFontFontsource } from "./webfont";
import { coverType } from "./typed";
import { coverHello } from "./typed";

// import "./alpine";

// ページ読み込み完了時に実行
window.addEventListener("DOMContentLoaded", function () {
  // コンポーネントのmodule.exports を処理実行させる
  backgroundColorChange();
  fadeInUp();
  hamburgerAnimation();
  headerScrollUpDisplay();
  heroMovie();
  heroSwiper();
  arrowCircleBlackAnimation();
  smoothScroll();
  webFontFontsource();
  coverType();
  coverHello();
});
