// 必要なnode_module読み込み
const { gsap } = require("gsap");
const { ScrollTrigger } = require("gsap/ScrollTrigger");

// コンポーネントのエクスポート
export const fadeInUp = () => {
  const trigger = ".js-fade-in-up";
  if (document.querySelector(trigger)) {
    gsap.registerPlugin(ScrollTrigger);
    gsap.defaults({
      ease: "power1",
    });
    gsap.set(trigger, {
      autoAlpha: 0,
      y: 200,
    });

    ScrollTrigger.batch(trigger, {
      onEnter: (batch) =>
        gsap.to(batch, {
          autoAlpha: 1,
          duration: 0.6,
          y: 0,
        }),
      start: "top bottom",
      once: true,
    });
  }
};

export const backgroundColorChange = () => {
  const trigger = ".js-bg-color-change";
  //   const trigger = gsap.utils.toArray(".js-bg-color-change");
  //   const container = document.querySelector(".js-change-bg-color-container");
  const startColor = "#666";
  const endColor = "#fff";

  // Show the given batch
  function changeBackgroundColor(batch) {
    batch.forEach((item) => {
      // Do whatever you want with each item in the batch
      gsap.timeline().to(item, {
        backgroundColor: endColor,
        overwrite: true,
      });
    });
  }

  // Hide the given batch
  function returnBackgroundColor(batch) {
    gsap.to(batch, { backgroundColor: startColor, overwrite: true });
  }

  if (document.querySelector(trigger)) {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.batch(trigger, {
      start: "top center",
      end: "90% center",
      ease: "none",
      onEnter: changeBackgroundColor,
      onLeave: returnBackgroundColor,
      onEnterBack: changeBackgroundColor,
      onLeaveBack: returnBackgroundColor,
    });
  }
};
