import Typed from "typed.js";

export const coverType = () => {
  const elem = "#js-cover-typed";
  if (document.querySelector(elem)) {
    const coverTyped = new Typed(elem, {
      stringsElement: "#js-cover-strings",
      typeSpeed: 30,
      startDelay: 3000,
      onComplete: () => {
        document.querySelector(".typed-cursor").style.display = "none";
      },
    });
    return coverTyped;
  }
};

export const coverHello = () => {
  const elemHello = "#js-hello-typed";
  if (document.querySelector(elemHello)) {
    const helloTyped = new Typed(elemHello, {
      stringsElement: "#js-hello-strings",
      typeSpeed: 200,
      onComplete: () => {
        document.querySelector(".typed-cursor").style.display = "none";
      },
    });
    return helloTyped;
  }
};
