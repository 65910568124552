export const heroMovie = () => {
  const elementId = document.querySelector(".js-hero-movie");
  const youtubeVideoID = "53_Asd3Nljw";
  if (!elementId) return;

  const tag = document.createElement("script");
  tag.src = "https://www.youtube.com/iframe_api";
  const iframePlayerApiScriptTag = document.getElementsByTagName("script")[0];
  iframePlayerApiScriptTag.parentNode.insertBefore(tag, iframePlayerApiScriptTag);

  class YouTubePlayer {
    constructor(youtubeVideoID) {
      this.ytPlayer = null;
      this.youtubeVideoID = youtubeVideoID;
      this.played = false;
    }

    //動画を再生
    mount(elementId) {
      // eslint-disable-next-line no-undef
      this.ytPlayer = new YT.Player(elementId, {
        width: "100%",
        height: "100%",
        videoId: this.youtubeVideoID,
        playerVars: {
          start: 0, // 動画開始位置
          rel: 0, // 関連動画の非表示
          controls: 0, // プレイヤーコントロールの非表示
          disablekb: 1, // キーボード操作をオフ
          showinfo: 0, // タイトルなどの非表示
          modestbranding: 1, // YouTubeロゴの非表示
          iv_load_policy: 3, // アノテーションの非表示
          fs: 0, //全画面表示ボタンの非表示
          autoplay: 1, //自動再生
          playsinline: 1, //インライン再生
          loop: 0, //ループ
          frameborder: 0,
        },
        events: {
          onReady: (event) => {
            this.onPlayerReady(event);
          },
          onStateChange: (event) => {
            this.onPlayerStateChange(event);
          },
        },
      });
    }

    // 動画の準備完了後の動作
    onPlayerReady(event) {
      event.target.setPlaybackQuality("highres");
      event.target.mute();
      event.target.playVideo();
    }

    // 動画再生中の操作
    onPlayerStateChange(event) {
      const ytStatus = event.target.getPlayerState();

      // 再生中
      // eslint-disable-next-line no-undef
      if (ytStatus == YT.PlayerState.PLAYING) {
        document.getElementById("js-hero-movie").classList.add("loaded");
        elementId.style.opacity = 0;
        elementId.style.visibility = "hidden";

        // ループ処理
        if (!this.played) {
          this.played = true;
          const duration = this.ytPlayer.getDuration();

          // eslint-disable-next-line no-unused-vars
          const timer = setInterval(() => {
            this.ytPlayer.seekTo(0);
            this.ytPlayer.playVideo();
          }, parseInt(duration * 1000));
        }
      }
    }
  }

  let video;

  window.onYouTubeIframeAPIReady = () => {
    video = new YouTubePlayer(youtubeVideoID);
    video.mount(elementId);
  };
};
