import Swiper, { Navigation, Pagination, Autoplay } from "swiper";

// import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";

Swiper.use([Navigation, Pagination, Autoplay]);

export const heroSwiper = () => {
  const element = ".js-hero-swiper";
  if (document.querySelector(element)) {
    new Swiper(element, {
      direction: "horizontal",
      loop: true,
      slidesPerView: "auto",
      speed: 1000,
      autoplay: {
        delay: 2500,
        disableOnInteraction: true,
      },
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },
    });
  }
};
